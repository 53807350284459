/*
*   Fonts
*
 */
$fontSizeBase: 16px;
$lineHeightBase: (22/16);
$fontDefault: 'Lausanne';

/*
*   Colors
*
 */
$colorBlack: #292929;
$colorWhite: #fff;
$colorGrey: #929292;
$colorLightGrey: #C4C4C4;
$colorRed: #E60000;
$colorBlue: #2055B9;
$colorMediumBlue: #29324D;
$colorDarkBlue: #0A1432;
$colorTurquoise: #b5f4ee;
$colorDarkTurquoise: #0BDBC7;
$colorText: $colorBlack;
$colorBackground: $colorWhite;

/*
*   Breakpoints
*
 */

// The different breakpoints are px values, but the unit has to be omitted
$small: 440;
$smallPlus: 600;
$medium: 750;
$mediumPlus: 980;
$large: 1200;
$largePlus: 1420;
$xlarge: 1800;

$breakpoints: (
        s: $small,
        sp: $smallPlus,
        m: $medium,
        mp: $mediumPlus,
        l: $large,
        lp: $largePlus,
        xl: $xlarge,
);

/*
*   Gutters, margins, max width
*
 */

$gutterMap: (
        min: 20px,
        s: 20px,
        sp: 20px,
        m: 40px,
        mp: 40px,
        l: 50px,
        lp: 50px,
        xl: 50px
);

$outerMarginsMap: (
        min: 22px,
        s: 22px,
        sp: 22px,
        m: 44px,
        mp: 44px,
        l: 72px,
        lp: 72px,
        xl: 72px
);

$maxContentWidth: 1440px;

// If different max widths are needed, comment this in and comment the variable above out.
//
// $maxContentWidth: (
//     min: 1280px,
//     s: 1280px,
//     sp: 1280px,
//     m: 1280px,
//     mp: 1280px,
//     l: 1280px,
//     lp: 1440px,
//     xl: 1600px
// );

// Which unit should the breakpoints use? em, px or rem (em is default)
$breakpointsUnit: px; // PX, EM or REM Media Queries? -> https://zellwk.com/blog/media-query-units/

// Grid plugin settings
$grid-breakpointMap: $breakpoints;
$grid-breakpoints: (s, sp, m, mp, l, lp, xl);
$grid-columns: 12;
$grid-max-width: $maxContentWidth; //Should include support for multiple max-widths at different breakpoings. See NAF Digital
$grid-outer-margins: $outerMarginsMap;
$grid-gutters: $gutterMap;
$grid-flexbox: true;
// $grid-flexbox-test-class: 'flexbox';
$grid-pull-columns: 6;
$grid-push-columns: 6;
$grid-use-long-classnames: false;
$grid-use-em: false;
//$grid-max-width-map: $maxContentWidth; // Optional. Use if different max widths are needed at different breakpoints
