.form {
    &__field {
        width: 100%;
        margin-bottom: 28px;
        input, textarea {
            display: block;
            padding: 17px 20px;
            background: transparent;
            border: 0;
            box-shadow: 0 0 0 1px rgba(0,0,0,.4);
            width: 100%;
            line-height: normal;
            transition: box-shadow 0.25s ease-out;
            &::placeholder {
                color: $colorBlack;
                opacity: 0.4;
            }
            &:focus {
                box-shadow: 0 0 0 1px rgba(0,0,0,1);
            }
            html.no-outline &:not(.invalid):hover {
                box-shadow: 0 0 0 1px rgba(0,0,0,1);
            }

            &.invalid:not(:focus) {
                box-shadow: 0 0 0 2px rgba(255,0,0,1);
            }
        }
        label {
            display: block;
            margin-bottom: 8px;
        }
        &--small {
            @include from(m) {
                width: calc(50% - 20px);
            }
        }
    }

    &__receipt {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__button {
        $this: &;
        line-height: normal;
        padding: 18px 50px;
        border-radius: 6px;
        &-arrow {
            will-change: opacity, transform;
            margin-right: 8px;
            transition: all 0.5s $easeInBack;
            transform: translateX(-10px);
            opacity: 0;
            .valid & {
                transition: all 0.5s $easeOutBack;
                transform: translateX(0);
                opacity: 1;
            }
        }
        &-text {
            will-change: transform;
            transition: transform 0.5s $easeInBack;
            transform: translateX(-10px);
            .valid & {
                transition: transform 0.5s $easeOutBack;
                transform: translateX(0);
            }
        }
        html.using-mouse &.valid:hover {
            #{ $this }-arrow {
                transition: all 0.3s ease-out;
                transform: translateX(5px);
            }
            #{ $this }-text {
                transition: transform 0.3s ease-out;
                transform: translateX(5px);
            }
        }
    }
}
