@font-face {
    font-family: 'Lausanne';
    src: url('../fonts/Lausanne-300.woff2') format('woff2'), url('../fonts/Lausanne-300.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lausanne';
    src: url('../fonts/Lausanne-300Italic.woff2') format('woff2'), url('../fonts/Lausanne-300Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

/*@font-face {
    font-family: 'Lausanne';
    src: url('../fonts/Lausanne-400.woff2') format('woff2'), url('../fonts/Lausanne-400.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lausanne';
    src: url('../fonts/Lausanne-400Italic.woff2') format('woff2'), url('../fonts/Lausanne-400Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}*/

@font-face {
    font-family: 'Lausanne';
    src: url('../fonts/Lausanne-500.woff2') format('woff2'), url('../fonts/Lausanne-500.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lausanne';
    src: url('../fonts/Lausanne-500Italic.woff2') format('woff2'), url('../fonts/Lausanne-500Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
