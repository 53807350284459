.hero-default {
    &__background {
        width: 100vw;
        height: 100vh;
        min-height: 1000px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 50%;
    }
}
