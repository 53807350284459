.page-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    color: $colorWhite;
    transition: color 0.3s ease-out;
    pointer-events: none;
    overflow: hidden;
    @include from(l) {
        overflow: visible;
    }
    a:not(.pe-n), button {
        pointer-events: auto;
    }
    .use-dark-header &, &.scroll-dark-header {
        color: $colorBlack;
    }

    &__logo {
        @include from(l) {
            margin-left: -36px;
            &-w {
                position: fixed;
                margin-bottom: 0;
            }
            &-letters {
                margin-left: 31px;
            }
        }
    }
    &__toggle {
        &:after {
            content: '';
            position: absolute;
            top: -0.2em;
            left: -0.2em;
            width: calc(100% + 2em);
            background: transparent;
            bottom: -0.2em;
        }
        &-close {
            position: absolute;
            left: 0;
            opacity: 0;
        }
        &-text {
            will-change: transform;
            transition: transform 0.35s ease-out;
        }
        .using-mouse &:hover &-text {
            transform: translateX(-5px);
        }
        &[aria-expanded="true"] {
            color: $colorBlack;
        }
    }
    &__hamburger {
        margin-top: 6px;
        margin-left: 10px;
        @include from(l) {
            position: fixed;
            margin: 0;
            top: 43px;
            transform: translateX(10px);
        }
    }
}
