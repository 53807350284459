.cookiebar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    &__button {
        border-radius: 6px;
        padding: 11px 16px 12px;
    }
}
