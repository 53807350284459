.u {
    &-wrap {
        max-width: maxWidth() + (outerMargin()*2);
        padding-left: outerMargin();
        padding-right: outerMargin();
        margin-left: auto;
        margin-right: auto;

        @each $breakpoint in map-keys($breakpoints) {
            @include from($breakpoint) {
                padding-left: outerMargin($breakpoint);
                padding-right: outerMargin($breakpoint);
                max-width: maxWidth($breakpoint) + (outerMargin($breakpoint)*2);
            }
        }
    }

    &-100-height {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }

    &-link {
        text-decoration: underline;
        text-decoration-color: currentColor;
        transition: text-decoration-color 0.3s ease-out;
        .using-mouse &:hover {
            text-decoration-color: transparent;
        }
    }

    &-link-inverted {
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: text-decoration-color 0.3s ease-out;
        .using-mouse &:hover {
            text-decoration-color: currentColor;
        }
    }

    &-clamp-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
