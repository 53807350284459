.wysiwyg {
    p, ul, ol {
        margin-bottom: 1.5em;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    ul, ol {
        padding-left: 1em;
    }

    li {
        display: list-item;
        margin-bottom: 1em;
        padding-left: 0.25em;
    }

    p, ul, ol, li {
        &:last-child {
            margin-bottom: 0;
        }
    }

    em {
        font-style: italic;
    }

    h2 {
        font-size: 20px;
        margin-top: 2.5em;
        margin-bottom: 1em;
        &:first-child {
            margin-top: 0;
        }
        @include from(m) {
            font-size: 24px;
        }
    }

    strong, h3 {
        font-weight: 500;
    }

    &__large-text {
        font-size: 1.5em;
    }

    &__large-text + p a {
        text-decoration: none !important;
        &:before {
            content: '→';
            display: inline-block;
            margin-right: 0.4em;
            color: $colorDarkTurquoise;
            transition: transform 0.3s ease-in-out;
        }
        &:hover:before {
            transform: translateX(0.25em);
        }
    }

    a:not([class]) {
        text-decoration: underline;
        text-decoration-color: currentColor;
        transition: text-decoration-color 0.3s ease-out;
        .using-mouse &:hover {
            text-decoration-color: transparent;
        }
    }
}
