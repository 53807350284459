.video-embed {
    opacity: 0;
    visibility: hidden;
    z-index: 100;
    background-color: transparentize($colorBlack, 0.15);

    &__close {
        right: 40px;
        top: 40px;
    }

    &__wrapper {
        width: 100%;
        height: 0;
        position: relative;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparentize($colorBlack, 0.2);
        }
    }
}
