/**
* --- Base setup and resets --------------------------------------------------------------------------------------------
*/

*, *::before, *::after {
    box-sizing: inherit;
}

html {
    font-size: 100%;
    text-size-adjust: 100%;
    height: 100%;
    box-sizing: border-box;
}

html, body {
    background-color: $colorBackground;
    color: $colorText;
}

body {
    position: relative;
    overflow-x: hidden;

    font-family: $fontDefault;
    font-weight: 400;
    font-size: $fontSizeBase;
    line-height: $lineHeightBase;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    min-height: 100%;
}


h1, h2, h3, h4, h5, h6 {
    font-size: 1em;
    display: block;
    margin: 0;
    font-weight: 400;
}

p, small, li {
    display: block;
    margin: 0;
}

ul, ol {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
}

img {
    display: block;
    width: 100%;
    height: auto;
}

figure {
    margin: 0;
}

a, input, button {
    -ms-touch-action: none !important;
}

button {
    border: 0;
    cursor: pointer;
    user-select: none;
    background: transparent;
    &[disabled] {
        cursor: default;
        opacity: 0.5;
    }
}

input {
    border-radius: 0;
}

input[type="radio"] {
    -webkit-appearance: radio;
}

input[type="checkbox"] {
    -webkit-appearance: checkbox;
}

textarea {
    resize: none;
}

select {
    border-radius: 0;
}

/* input:-webkit-autofill { -webkit-box-shadow: 0 0 0 1000px $colorInputBackground inset; } */
input::-ms-clear {
    display: none;
}

input[type="search"], input[type="text"], input[type="email"], textarea {
    -webkit-appearance: none;
    border-radius: 0;
    box-sizing: border-box;
}

fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

picture {
    display: block;
}

address {
    font-style: normal;
}

a {
    color: inherit;
    text-decoration: none;
}

b, strong {
    font-weight: normal;
}

i, em {
    font-style: normal;
}

button:not(:focus) {
    outline: 0;
}

.no-outline {
    button, a, input, label, textarea, select, option {
        outline: none !important;
    }
}

.lazyload, .lazyloading {
    opacity: 0;
    transform: translateZ(0);
    &.lazyscale {
        transform: translateZ(0) scale(1.1);
    }
}

.lazyloaded {
    opacity: 1;
    transition: opacity 0.75s ease-out;
    &.lazyscale {
        transform: translateZ(0) scale(1);
        transition: opacity 0.75s ease-out, transform 3.5s $easeOutCirc;
    }
}

.no-js {
    img.lazyload {
        display: none;
    }
}

[hidden] {
    display: none !important;
}

a, button {
    .arrow-left, .arrow-right, .arrow-up, .arrow-right-up, .arrow-right-down, .arrow-down {
        display: inline-block;
        transition: transform 0.3s ease-in-out;
    }
    .using-mouse &:hover:not([disabled]), &:focus:not([disabled]) {
        .arrow-left {
            transform: translateX(-0.25em);
        }
        .arrow-right {
            transform: translateX(0.25em);
        }
        .arrow-right-up {
            transform: translate(0.25em, -0.25em);
        }
        .arrow-right-down {
            transform: translate(0.25em, 0.25em);
        }
        .arrow-up {
            transform: translateY(-0.25em);
        }
        .arrow-down {
            transform: translateY(0.25em);
        }
    }
}

html.js {
    [data-reveal] {
        transform: translateY(4vh);
        opacity: 0;
        @media screen and (prefers-reduced-motion: reduce), (update: slow) {
            transform: none !important;
            opacity: 1 !important;
        }
    }
    [data-reveal-y] {
        opacity: 1;
    }
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
    *,
    *::before,
    *::after {
        animation-duration: 0.001ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.001ms !important;
    }
}

