.page-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 9;
    background: $colorWhite;
    color: $colorBlack;

    @include from(m) {
        left: 50%;
        width: 50%;
    }

    &__shader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        z-index: 8;
        opacity: 0;
        visibility: hidden;
    }

    &:target {
        display: flex !important;
    }

    &:target + &__shader {
        opacity: 0.8;
        visibility: visible;
    }
}
