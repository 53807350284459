.video-loop {

    &__video {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.75s $easeInSine;
        .js-is-playing &,
        &.js-is-playing {
            visibility: visible;
            opacity: 1;
        }
    }
}
