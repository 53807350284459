.radiobutton {
    position: relative;
    display: flex;
    margin-bottom: 7px;
    cursor: pointer;

    span {
        margin-top: -3px;
    }

    i {
        position: relative;
        flex: 0 0 16px;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        margin-right: 10px;
        border: 1px solid transparentize(#292929, 0.5);
        &:after {
            position: absolute;
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background: #292929;
            top: 2px;
            left: 2px;
            opacity: 0;
        }
    }

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        //opacity: 0;
        pointer-events: none;
        appearance: none;
        border: 0;
    }

    input:checked + i {
        &:after {
            opacity: 1;
        }
    }

    &:hover {
        input:not(:checked) + i {
            border: 1px solid #292929;
        }
    }
}
